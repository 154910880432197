// i18next-extract-mark-ns-start about-us-our-company

import { LINKS } from '@utils/constants';
import { graphql } from 'gatsby';
import React from 'react';

import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import Video from "@components/Video";
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';


const AboutPage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation();

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: 'Company',
				}}
				size="standard"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection className="section__content-center" layout="wide">
				<Trans i18nKey="content">
					<h2>Accelerating possibilities</h2>
					<Video
						videoSrcURL="https://youtube.com/embed/QFxjoIpBbr8?playlist=QFxjoIpBbr8&loop=1"
						videoTitle="Welcome to Realis Simulation"
					/>
					<p>
						At Realis Simulation, formerly Ricardo Software, our aim is
						to help you solve the problems that matter through
						technology exploration and process innovation.
						We deliver advanced virtual engineering tools, supported by
						a team of technical experts, to global partners across
						automotive, rail, motorcycle, off-highway, defence, energy
						and environment industries.
					</p>
					<p>
						Our dedicated Software team is made up of world-class engineers,
						developers, industry experts and technical consultants with over 30 years of experience
						and unrivalled expertise and industry know-how, ensuring we can respond to each client’s individual needs.
						Our leading-edge simulation software enables users to
						quickly and accurately create, analyse and optimise complex
						physical systems - from initial concept to final design.
					</p>
					<p>
						Together, we enable customers to reduce R&amp;D costs and
						bring products to market faster, with confidence.
					</p>
					<p>
						For more information,{' '}
						<Link to={LINKS.ABOUT_CONTACT}>contact us</Link> today!
					</p>
				</Trans>
			</PageSection>
		</Page>
	);
};

export default AboutPage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["about-us-our-company", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/about-us/our-company/Realis-Simulation_CompanyOverview.png" }) {
		...imageBreaker
	}
}
`;