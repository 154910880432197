import { IVideo } from '@utils/types';
import React from "react";

const Video = ({ videoSrcURL, videoTitle }: IVideo) => (
	<div className="video">
		<iframe
			src={videoSrcURL}
			title={videoTitle}
			allow="accelerometer; autoplay; loop; encrypted-media; gyroscope; picture-in-picture"
			width="640px"
			height="360px"
			allowFullScreen
		/>
	</div>
);
export default Video;